import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import not_hesapla from "../../Assets/Projects/not_hesapla.jpg"
import online_code_editor from "../../Assets/Projects/online_code_editor.png"
import report from "../../Assets/Projects/report.png"
import speed_up_pc_and_internet from "../../Assets/Projects/speed_up_pc_and_internet.png"
import kodlacoz from "../../Assets/Projects/kodlacoz.png"
import form_beyaz from "../../Assets/Projects/form_beyaz.png"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
         Projelerim
        </h1>
        <p style={{ color: "white" }}>
         <strong className="purple">Üzerinde çalıştığım</strong> veya <strong className="purple">Tamamlanmış olan</strong> bazı projelerim
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kodlacoz}
              hasGithub={false}
              title="HTC Türkiye"
              description="Kullanıcıların algoritma soruları çözerek algoritma yeteneklerini geliştirebilecekleri bir platform olan bu uygulama, Java Spring ve Thymeleaf ile geliştirilmektedir. Veri tabanı olarak PostgreSQL kullanılmaktadır. Bu proje Tübitak 2209-A programı kapsamında destek almıştır. (Hala geliştiriliyor)"
              demoLink="https://github.com/canermastan"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={report}
              hasGithub={true}
              title="Laboratuvar Raporlama Sistemi"
              description="
              Bir laboratuvar raporlama sistemi olan bu uygulama, Java Spring Boot ve React.js kullanılarak geliştirildi. Uygulama, hastaların bilgilerini ve diğer ilgili verileri raporlama amacıyla kaydetmeye ve yönetmeye olanak sağlar."
              ghLink="https://github.com/canermastan/Laboratory-Reporting-System-API"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={online_code_editor}
              hasGithub={true}
              title="Online Code Editor"
              description="Online Code Editor, Ace C9 kod editörü kullanılarak geliştirilmiş bir uygulamadır. Bu uygulama, kullanıcılara kod yazma ve düzenleme imkanı sunar. Java Spring Boot teknolojisiyle entegre çalışır ve kullanıcıların yazdıkları kodları sunucuda çalıştırarak çıktıyı ekrana bastırır. Online Code Editor, öğrenme, kod paylaşımı veya hızlı prototipleme gibi birçok amaç için kullanılabilir."
              ghLink="https://github.com/canermastan/Online-Code-Editor"             
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={form_beyaz}
              hasGithub={false}
              title="Form Oluşturma Uygulaması"
              description="Bu uygulama, form oluşturma işlemlerini kolaylaştıran bir platform sunar. Kullanıcılar, özelleştirilebilir form şablonlarından yararlanarak kendi formlarını oluşturabilirler. Formda yer alacak soruları, alanları ve diğer özellikleri belirleyebilirler. (Hala geliştiriliyor)"
              demoLink="https://github.com/canermastan"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={speed_up_pc_and_internet}
              hasGithub={true}
              title="PC & Internet Optimizer"
              description="Bu C# tabanlı masaüstü uygulaması, bilgisayarın genel performansını ve internet bağlantısını optimize etmek için tasarlanmıştır. Uygulama, kullanıcılara bilgisayarlarının daha hızlı ve verimli çalışmasını sağlama imkanı sunar."
              ghLink="https://github.com/canermastan/Speed-Up-PC-And-Internet"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={not_hesapla}
              hasGithub={true}
              title="Vize ve Final Ortalama Hesaplama Uygulaması"
              description="Bu mobil uygulama, vize ve final notlarını baz alarak öğrencilerin dönemlik ortalamalarını hesaplamak için tasarlanmıştır. React Native teknolojisi kullanılarak geliştirilen bu uygulama, kullanıcılara kolay ve pratik bir şekilde not ortalamalarını hesaplama imkanı sunar."
              ghLink="https://github.com/canermastan/NotHesapla_ReactNative"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
